<template>
  <div></div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  components: {},

  props: {},

  data() {
    return {}
  },

  computed: {},

  created() {},

  methods: {},
}
</script>

<style></style>
